<template>
	<v-sheet :class="{ 'internal-table-listing': internal, 'listing-table': true }">
		<template v-if="!pageLoading">
			<v-simple-table class="bt-table listing-table">
				<template v-slot:default>
					<thead>
						<tr>
							<th v-for="(th, key) in sortedThead" :key="key" :width="th.width">
								<v-layout>
									<v-flex class="text-left">
										<template v-if="th.checkbox">
											<v-checkbox
												dense
												v-if="getPermission(`${type}::edit`)"
												v-model="checkbox"
												hide-details
												color="blue darken-4"
												class="m-0 p-0"
												v-on:change="selectAll"
												:indeterminate="indeterminate"
											></v-checkbox>
										</template>
										<template v-else>
											{{ th.name }}
										</template>
									</v-flex>
									<v-flex v-if="!th.checkbox && th.sortable" class="text-right ml-2">
										<template v-if="th.sort == 'ASC'"
											><v-icon small link>mdi-sort-ascending</v-icon></template
										>
										<template v-if="th.sort == 'DESC'"
											><v-icon small link>mdi-sort-descending</v-icon></template
										>
									</v-flex>
								</v-layout>
							</th>
						</tr>
					</thead>
					<tbody v-if="tbody.length">
						<template v-for="(td, bkey) in tbody">
							<v-hover :key="`row-hover-${bkey}`" v-slot="{ hover }">
								<tr :key="`row-${bkey}`">
									<template v-for="(th, hkey) in sortedThead">
										<td
											:key="`first-${hkey}-${bkey}`"
											:width="th.width"
											v-on:click="routeToDetail(th.order, td.uuid)"
											:class="{ 'td-link': th.order > 1 }"
											:style="`min-width:${th.width}px`"
										>
											<template v-if="th.checkbox">
												<v-checkbox
													dense
													v-if="getPermission(`${type}::edit`)"
													v-model="selectedRows"
													color="blue darken-4"
													:value="td.uuid"
													hide-details
													class="m-0 p-0"
													multiple
													v-on:change="updateSelected()"
												></v-checkbox>
											</template>
											<template v-else-if="th.key == 'action'">
												
												<template v-if="getPermission(`${type}::edit`)">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																depressed
																fab
																:dark="!is_edit_disabled(td)"
																:disabled="is_edit_disabled(td)"
																x-small
																color="green"
																v-bind="attrs"
																v-on="on"
																class="mr-3"
																v-on:click.stop.prevent="routeToUpdate(td.uuid)"
															>
																<v-icon>mdi-pencil</v-icon>
															</v-btn>
														</template>
														<span>Edit {{ type }}</span>
													</v-tooltip>
												</template>
												<template v-if="getPermission(`${type}::delete`)">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																depressed
																fab
																:dark="!is_delete_disabled(td)"
																:disabled="is_delete_disabled(td)"
																x-small
																color="red"
																v-bind="attrs"
																v-on="on"
																class=""
																v-on:click.stop.prevent="deleteConfirm(td)"
															>
																<v-icon>mdi-delete</v-icon>
															</v-btn>
														</template>
														<span>Delete {{ type }}</span>
													</v-tooltip>
												</template>
												<template v-if="type == 'service'">
													<template v-if="getPermission('invoice::create')">
														<v-tooltip top content-class="custom-top-tooltip">
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	depressed
																	fab
																	:dark="!is_invoice_disabled(td)"
																	x-small
																	:disabled="is_invoice_disabled(td)"
																	color="blue darken-4"
																	v-bind="attrs"
																	v-on="on"
																	v-on:click.stop.prevent="convert_to_invoice(td)"
																>
																	<v-icon>mdi-file-refresh-outline</v-icon>
																</v-btn>
															</template>
															<span>Invoice</span>
														</v-tooltip>
													</template>
												</template>
												<template v-if="type == 'invoice'">
													<template v-if="getPermission('payment::create')">
														<v-tooltip top content-class="custom-top-tooltip">
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	depressed
																	fab
																	:dark="!is_payment_disabled(td)"
																	x-small
																	:disabled="is_payment_disabled(td)"
																	color="blue darken-4"
																	v-bind="attrs"
																	v-on="on"
																	v-on:click.stop.prevent="convert_to_payment(td)"
																>
																	<v-icon>mdi-currency-usd-circle-outline</v-icon>
																</v-btn>
															</template>
															<span>Payment</span>
														</v-tooltip>
													</template>
												</template>
											</template>
											<template v-else-if="th.key == 'image'">
												<ImageTemplate circle style="max-width: 50px" :src="td.image"> </ImageTemplate>
											</template>
											<template v-else-if="th.key == 'details' && th.type == 'request-po'">
												<div>
													<span class="fw-500">Ref : </span>
													<ShowValue
														tooltip
														tooltip-text="reference number"
														:object="td"
														object-key="reference_number"
														label="reference number"
													></ShowValue>
												</div>

												<div>
													<span class="fw-500">Delivery Date : </span>
													<template v-if="td.date">
														{{ formatDate(td.date) }}
													</template>
													<template v-else>
														<em class="text-muted">no date</em>
													</template>
												</div>
												<div v-if="false">
													<span class="fw-500">Create Date : </span>
													<template v-if="td.added_at">
														{{ td.added_at }}
													</template>
													<template v-else>
														<em class="text-muted">no create date</em>
													</template>
												</div>
											</template>
											<template
												v-else-if="
													(th.type == 'request-po' || th.type == 'quotation' || th.type == 'claim-expense') &&
													th.key == 'customer_name'
												"
											>
												<div>
													<v-icon small left>mdi-home-city-outline</v-icon>
													<ShowValue
														tooltip
														tooltip-text="company name"
														:object="td"
														object-key="customer_company_name"
														label="company name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														tooltip-text="display name"
														:object="td"
														object-key="customer_name"
														label="display name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="customer_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="phone number"
														:object="td"
														object-key="customer_phone"
														label="phone number"
													>
													</ShowValue>
												</div>
											</template>
											<template
												v-else-if="
													(th.type == 'request-po' || th.type == 'quotation' || th.type == 'claim-expense') &&
													th.key == 'customer_contact_person'
												"
											>
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														tooltip-text="display name"
														:object="td"
														object-key="customer_contact_person_display_name"
														label="display name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="customer_contact_person_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="phone number"
														:object="td"
														object-key="customer_contact_person_phone_number"
														label="phone number"
													>
													</ShowValue>
												</div>
											</template>
											<template v-else-if="th.type == 'request-po' && th.key == 'supplier_name'">
												<div>
													<v-icon small left>mdi-home-city-outline</v-icon>
													<ShowValue
														tooltip
														tooltip-text="company name"
														:object="td"
														object-key="supplier_company_name"
														label="company name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														tooltip-text="display name"
														:object="td"
														object-key="supplier_name"
														label="display name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="supplier_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="phone number"
														:object="td"
														object-key="supplier_phone"
														label="phone number"
													>
													</ShowValue>
												</div>
											</template>
											<template v-else-if="th.type == 'request-po' && th.key == 'supplier_contact_person'">
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														tooltip-text="display name"
														:object="td"
														object-key="supplier_contact_person_display_name"
														label="display name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="supplier_contact_person_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="phone number"
														:object="td"
														object-key="supplier_contact_person_phone_number"
														label="phone number"
													>
													</ShowValue>
												</div>
											</template>
											<template
												v-else-if="
													(th.type == 'request-po' || th.type == 'quotation' || th.type == 'claim-expense') &&
													th.key == 'project_name'
												"
											>
												<div :style="`min-width:${th.width}px`">
													<template
														v-if="
															td.project_name ||
															td.project_price ||
															td.project_status_value ||
															td.project_start_date ||
															td.project_end_date
														"
													>
														<div :style="`width:${th.width}px;`" class="text-truncate">
															<span class="fw-500">Name : </span>
															<ShowValue
																truncate
																:object="td"
																object-key="project_name"
																label="project name"
															></ShowValue>
														</div>
														<div class="d-flex">
															<span>
																<span class="fw-500">Price : </span>
																<span class="fw-600">
																	<ShowPrice
																		:object="td"
																		object-key="project_price"
																		label="project cost"
																	></ShowPrice>
																</span>
															</span>
															<v-spacer></v-spacer>
															<v-tooltip top v-if="false">
																<template v-slot:activator="{ on, attrs }">
																	<v-icon
																		v-on="on"
																		v-bind="attrs"
																		class="text-right"
																		size="16"
																		:color="getStatusColor(td.project_status_value)"
																		>mdi-circle</v-icon
																	>
																</template>
																<span
																	>Project Status -
																	<span class="text-uppercase">{{ td.project_status_value }}</span></span
																>
															</v-tooltip>
														</div>
														<div>
															<span class="fw-500">Start : </span>
															<template v-if="td.project_start_date">
																{{ formatDate(td.project_start_date) }}
															</template>
															<template v-else>
																<em class="text-muted">no start date</em>
															</template>
														</div>
														<div>
															<span class="fw-500">End : </span>
															<template v-if="td.project_end_date">
																{{ formatDate(td.project_end_date) }}
															</template>
															<template v-else>
																<em class="text-muted">no end date</em>
															</template>
														</div>
														<div>
															<span class="fw-500">Status : </span>
															<v-tooltip top>
																<template v-slot:activator="{ on, attrs }">
																	<v-chip
																		:color="getStatusColor(td.project_status_value)"
																		label
																		small
																		outlined
																		v-on="on"
																		v-bind="attrs"
																		class="white--text"
																	>
																		<span class="text-uppercase">
																			{{ td.project_status_value }}
																		</span>
																	</v-chip>
																</template>
																<span>Project Status</span>
															</v-tooltip>
														</div>
													</template>
													<em v-else class="text-muted">no project</em>
												</div>
											</template>
											<template v-else-if="th.key == 'project_transfer_barcode'">
												<div
													v-on:click.stop.prevent="copyBarcode(td.transfer_projects_barcode)"
													class="d-inline-block"
												>
													<Chip :text="td.transfer_projects_barcode" color="cyan" class="cursor-pointer"> </Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'from'">
												<div class="d-inline-block">
													<div class="mt-1">
														{{ td.transfer_from }}
													</div>
													<Chip :text="td.transfer_form_barcode" color="cyan" class="cursor-pointer"> </Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'to'">
												<div class="d-inline-block">
													<div class="mt-1">
														{{ td.transfer_to }}
													</div>
													<Chip :text="td.transfer_to_barcode" color="cyan" class="cursor-pointer"> </Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'remark'">
												<div class="mt-1 text-overflow">
													{{ td.transfer_projects_remark }}
												</div>
											</template>
											<template v-else-if="th.key == 'status'">
												<div class="d-inline-block">
													<Chip
														:text="td.transfer_projects_status"
														:color="getStatusColor(td.transfer_projects_status)"
														class="cursor-pointer"
													>
													</Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'barcode'">
												<div v-on:click.stop.prevent="copyBarcode(td.barcode)" class="d-inline-block mb-1">
													<Chip
														:text="td.barcode"
														tooltip
														:tooltip-text="getBarcodeTooltipText(th.type)"
														small
														color="cyan"
														class="cursor-pointer"
													></Chip>
												</div>
												<div class="d-flex cursor-pointer" v-if="td && td.request_po_barcode">
													<span v-on:click="RequestPoDetail(td.request_po_uuid)">
														<Chip :text="td.request_po_barcode" small color="green"></Chip>
													</span>
												</div>
												<div class="d-flex mt-1">
													<template v-if="td.status_value != 'pending'">
														<Status
															class="mb-1 mr-1"
															small
															tooltip
															tooltip-text="Status"
															:status="td.status_value"
														>
														</Status>
													</template>
													<template v-if="td.status_value == 'pending'">
														<Chip
															color="yellow darken-3"
															small
															custom-class="mb-1 mr-1 white--text px-2"
															text="pending"
														>
														</Chip>
													</template>

													<template v-if="td.grn_receive && td.grn_receive.length">
														<template v-for="(data, barcodeIndex) in td.grn_receive">
															<template v-if="barcodeIndex < 4 && data">
																<v-icon size="12" class="mb-1" :key="barcodeIndex + 'icon'">mdi-arrow-right</v-icon>
																<Chip
																	tooltip
																	:key="barcodeIndex + 'chip'"
																	:tooltip-text="
																		data.force_closed_value ? data.force_closed_value : data.receive_status_value
																	"
																	small
																	:label="true"
																	:outlined="false"
																	:color="
																		getStatusColor(
																			data.force_closed_value ? data.force_closed_value : data.receive_status_value
																		)
																	"
																	custom-class="mb-1 mr-1 white--text px-2"
																	:text="
																		data.force_closed_value
																			? data.force_closed_value.slice(0, 1).toUpperCase()
																			: data.receive_status_value.slice(0, 1).toUpperCase()
																	"
																>
																</Chip>
															</template>
															<template v-if="barcodeIndex === 4">
																<span class="fw-600" :key="barcodeIndex + 'dots'">...</span>
															</template>
														</template>
													</template>
													<template>
														<template
															v-if="
																td.receive_status_value && lower_case(td.receive_status_value) == 'fully received'
															"
														>
															<v-icon size="12" class="mb-1">mdi-arrow-right</v-icon>
															<Chip
																small
																:label="true"
																:outlined="false"
																tooltip
																:tooltip-text="td.receive_status_value"
																:color="getStatusColor(td.receive_status_value)"
																custom-class="mb-1 mr-1 white--text px-2"
																:text="lower_case(td.receive_status_value) == 'partially received' ? 'P' : 'F'"
															>
															</Chip>
														</template>
														<template v-if="false && td.force_close_status_value">
															<Chip
																v-if="td.force_close_status_value"
																tooltip
																:tooltip-text="td.force_close_status_value"
																small
																:label="true"
																:outlined="false"
																:color="getStatusColor(td.force_close_status_value)"
																custom-class="mb-1 mr-1 white--text px-2"
																text="F"
															></Chip>
														</template>
													</template>
												</div>
												<template v-if="td.is_revised">
													<Chip
														color="red darken-3"
														small
														custom-class="mb-1 mr-1 white--text px-2"
														text="Revised"
													>
													</Chip>
												</template>
												<template v-if="td.rating">
													<v-rating
														size="16"
														v-model="td.rating"
														:value="td.rating"
														background-color="orange lighten-3"
														color="orange"
														readonly
													></v-rating>
												</template>
												<!-- <template v-if="td?.rpo_status_value">
														<Chip
															:text="td.rpo_status_value.replace('_', ' 	')"
															small
															:color="getStatusColor(td.rpo_status_value)"
														></Chip>
													</template> -->
											</template>
											<template v-else-if="th.key == 'project_barcode'">
												<div
													v-if="td.project_barcode"
													v-on:click.stop.prevent="copyBarcode(td.project_barcode)"
													class="d-inline-block"
												>
													<Chip
														:text="td.project_barcode"
														small
														color="blue darken-4"
														class="cursor-pointer"
													></Chip>
												</div>
												<em v-else class="text-muted">No Project</em>
											</template>
											<template v-else-if="th.key == 'formatDate_barcode'">
												<div v-on:click.stop.prevent="copyBarcode(td.customer_barcode)" class="d-inline-block">
													<Chip :text="td.customer_barcode" color="cyan" class="cursor-pointer"> </Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'status'">
												<v-col md-12>
													<Status class="mb-1 mr-1" tooltip tooltip-text="Status" :status="td.status_value">
													</Status>
													<div>
														<v-chip
															v-if="td.receive_status_value"
															label
															:color="getStatusColor(td.receive_status_value)"
															class="mb-1 mr-1 white--text"
														>
															{{ td.receive_status_value }}
														</v-chip>
													</div>
													<div>
														<v-chip
															v-if="td.force_close_status_value"
															label
															:color="getStatusColor(td.force_close_status_value)"
															class="mb-1 mr-1 white--text"
														>
															{{ td.force_close_status_value }}
														</v-chip>
													</div>
												</v-col>
											</template>
											<template
												v-else-if="
													(th.type == 'customer' || th.type == 'vendor') && th.key == 'company_contact'
												"
											>
												<div>
													<v-icon small left>mdi-barcode</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="UEN number"
														:object="td"
														object-key="uen"
														label="uen"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														tooltip-text="display name"
														:object="td"
														object-key="display_name"
														label="display name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-home-city-outline</v-icon>
													<ShowValue
														tooltip
														tooltip-text="company name"
														:object="td"
														object-key="company_name"
														label="company name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="company email"
														:object="td"
														object-key="email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="company phone number"
														:object="td"
														object-key="phone_number"
														label="phone number"
													>
													</ShowValue>
												</div>
											</template>
											<template
												v-else-if="(th.type == 'customer' || th.type == 'vendor') && th.key == 'contact_person'"
											>
												<div v-if="td.default_person_primary == 1">
													<v-chip small label color="green white--text text-uppercase" class="px-2">
														Primary
													</v-chip>
												</div>
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														tooltip-text="display name"
														:object="td"
														object-key="default_person_display_name"
														label="display name"
													>
													</ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="default_person_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="mobile"
														:object="td"
														object-key="default_person_phone"
														label="mobile"
													>
													</ShowValue>
												</div>
												<div>
													<v-chip
														v-if="td.contact_people_count > 1"
														x-small
														label
														color="blue darken-4 white--text"
														class="px-1"
														@click.stop.prevent="routeToGo('contact_person', td.uuid)"
													>
														+{{ td.contact_people_count - 1 }} more
													</v-chip>
												</div>
											</template>
											<template
												v-else-if="
													(th.type == 'customer' || th.type == 'vendor') && th.key == 'company_address'
												"
											>
												<div class="d-flex" v-if="td.address_line_1 || td.address_line_2">
													<div>
														<v-icon size="16" class="mr-1">mdi-map-marker</v-icon>
													</div>
													<div>
														<p class="mb-0">
															<template v-if="td.address_line_1"> {{ td.address_line_1 }},</template>
															<template v-if="td.address_line_2">{{ td.address_line_2 }},</template>
														</p>
														<p class="mb-0">
															<template v-if="td.unit_number"> {{ td.unit_number }}, </template>
															<template v-if="td.address_country"> {{ td.address_country }}, </template>
															{{ td.address_postal_code ? td.address_postal_code : null }}
														</p>
													</div>
												</div>
											</template>

											<template
												v-else-if="th.type == 'product-stock-history' && th.key == 'product_serial_number'"
											>
												<div v-on:click.stop.prevent="goToProduct(td)" class="d-inline-block mb-1">
													<Chip
														:text="td.product_barcode"
														tooltip
														tooltip-text="product ID #"
														color="cyan"
														class="cursor-pointer"
													></Chip>
												</div>
											</template>
											<template v-else-if="th.type == 'product-stock-history' && th.key == 'inital_stock'">
												<ShowValue
													:object="td"
													:class="{ 'red--text': td.inital_stock < 0 }"
													object-key="inital_stock"
													label="initial stock"
												>
												</ShowValue>
											</template>
											<template v-else-if="th.type == 'product-stock-history' && th.key == 'stock_quantity'">
												<ShowValue
													:object="td"
													:class="{ 'red--text': td.stock_quantity < 0 }"
													object-key="stock_quantity"
													label="stock stock"
												>
												</ShowValue>
											</template>
											<template v-else-if="th.type == 'product-stock-history' && th.key == 'total_stock'">
												<ShowValue
													:object="td"
													:class="{ 'red--text': td.total_stock < 0 }"
													object-key="total_stock"
													label="total stock"
												>
												</ShowValue>
											</template>
											<template v-else-if="th.type == 'product-stock-history' && th.key == 'parent_barcode'">
												<div v-on:click.stop.prevent="goToPurchaseOrder(td)" class="d-inline-block mb-1">
													<Chip
														:text="td.parent_barcode"
														tooltip
														tooltip-text="product #"
														color="cyan"
														class="cursor-pointer"
													></Chip>
												</div>
											</template>
											<template v-else-if="th.type == 'product-stock-history' && th.key == 'supplier'">
												<div class="d-flex align-content-start flex-wrap align-start flex-colum">
													<div>
														<v-icon small left>mdi-barcode</v-icon>
														<v-chip
															v-if="td.supplier_barcode"
															outlined
															small
															label
															color="cyan white--text text-uppercase"
															class="px-2"
														>
															{{ td.supplier_barcode }}
														</v-chip>
														<em v-else class="text-muted fw-400">no barcode</em>
													</div>
													<div>
														<v-icon small left>mdi-home-city-outline</v-icon>
														<ShowValue
															tooltip
															tooltip-text="company name"
															:object="td"
															object-key="supplier_company_name"
															label="company name"
														></ShowValue>
													</div>
													<div>
														<v-icon small left>mdi-account</v-icon>
														<ShowValue
															tooltip
															tooltip-text="display name"
															:object="td"
															object-key="supplier_name"
															label="display name"
														></ShowValue>
													</div>
													<div>
														<v-icon small left>mdi-email</v-icon>
														<ShowValue
															tooltip
															click-to-copy
															tooltip-text="Email"
															:object="td"
															object-key="supplier_email"
															label="email"
														></ShowValue>
													</div>
													<div>
														<v-icon small left>mdi-phone</v-icon>
														<ShowValue
															tooltip
															click-to-copy
															tooltip-text="phone number"
															:object="td"
															object-key="supplier_phone"
															label="phone number"
														>
														</ShowValue>
													</div>
												</div>
											</template>
											<template v-else-if="th.type == 'product-stock-history' && th.key == 'date'">
												<template v-if="td.date">
													{{ formatDate(td.date) }}
												</template>
												<template v-else>
													<em class="text-muted">no date</em>
												</template>
											</template>
											<template v-else-if="th.type == 'product-stock-history' && th.key == 'total_price'">
												<ShowPrice :object="td" object-key="total_price" label="total price"></ShowPrice>
											</template>
											<template v-else-if="th.type == 'product-stock-history' && th.key == 'item_price'">
												<ShowPrice :object="td" object-key="item_price" label="total stock"></ShowPrice>
											</template>
											<template v-else-if="th.type == 'product-stock-history' && th.key == 'customer'">
												<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
													<div>
														<v-icon small left>mdi-barcode</v-icon>
														<v-chip
															v-if="td.customer_barcode"
															outlined
															small
															label
															color="cyan white--text text-uppercase"
															class="px-2"
														>
															{{ td.customer_barcode }}
														</v-chip>
														<em v-else class="text-muted fw-400">no barcode</em>
													</div>
													<div>
														<v-icon small left>mdi-home-city-outline</v-icon>
														<ShowValue
															tooltip
															tooltip-text="company name"
															:object="td"
															object-key="customer_company_name"
															label="company name"
														></ShowValue>
													</div>
													<div>
														<v-icon small left>mdi-account</v-icon>
														<ShowValue
															tooltip
															tooltip-text="display name"
															:object="td"
															object-key="customer_name"
															label="display name"
														></ShowValue>
													</div>
													<div>
														<v-icon small left>mdi-email</v-icon>
														<ShowValue
															tooltip
															click-to-copy
															tooltip-text="Email"
															:object="td"
															object-key="customer_email"
															label="email"
														></ShowValue>
													</div>
													<div>
														<v-icon small left>mdi-phone</v-icon>
														<ShowValue
															tooltip
															click-to-copy
															tooltip-text="phone number"
															:object="td"
															object-key="customer_phone"
															label="phone number"
														>
														</ShowValue>
													</div>
												</div>
											</template>

											<template v-else-if="th.type == 'customer' && th.key == 'billing_address'">
												<div v-if="td.default_location_primary == 1">
													<v-chip small label color="green white--text text-uppercase" class="px-2">
														billing address
													</v-chip>
												</div>
												<div v-else>
													<v-chip
														small
														label
														color="blue lighten-4 blue--text text--darken-4 text-uppercase"
														class="px-2"
													>
														service location
													</v-chip>
												</div>
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Display Name"
														:object="td"
														object-key="default_location_name"
														label="display name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="default_location_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Phone Number"
														:object="td"
														object-key="default_location_phone_no"
														label="phone number"
													></ShowValue>
												</div>
												<div class="d-flex" v-if="td.default_location_address_line_1">
													<div>
														<v-icon size="16" class="mr-1">mdi-map-marker</v-icon>
													</div>
													<div>
														<p class="mb-0 text-overflow" v-if="td.default_location_address_line_1">
															{{ td.default_location_address_line_1 }},
															<template v-if="td.default_location_address_line_2">
																{{ td.default_location_address_line_2 }},
															</template>
														</p>
														<p class="mb-0" v-if="td.default_location_unit_number">
															{{ td.default_location_unit_number }},
															<template v-if="td.default_location_address_country">
																{{ td.default_location_address_country }},
															</template>
															{{
																td.default_location_address_postal_code
																	? td.default_location_address_postal_code
																	: null
															}}
														</p>
														&nbsp;
														<v-chip
															v-if="td.billing_address_count > 1"
															x-small
															label
															color="blue darken-4 white--text"
															class="px-1"
															@click.stop.prevent="routeToGo('billing_address', td.uuid)"
														>
															+{{ td.billing_address_count - 1 }} more
														</v-chip>
													</div>
												</div>
											</template>
											<template v-else-if="th.type == 'project' && th.key == 'project_detail'">
												<div>
													<span class="fw-500">Name : </span>
													<ShowValue :object="td" object-key="name" label="name"></ShowValue>
												</div>
												<div>
													<span class="fw-500">Starts Date : </span>
													<template v-if="td.start_date">
														{{ td.start_date }}
													</template>
													<template v-else>
														<em class="text-muted">no date</em>
													</template>
												</div>
												<div>
													<span class="fw-500">End Date : </span>
													<template v-if="td.end_date">
														{{ td.end_date }}
													</template>
													<template v-else>
														<em class="text-muted">no date</em>
													</template>
												</div>
											</template>
											<template v-else-if="th.type == 'project' && th.key == 'project_amount'">
												<div>
													<span class="fw-500">Project Value : </span>
													<ShowPrice :object="td" object-key="project_value" label="Project Value"></ShowPrice>
												</div>
												<div>
													<span class="fw-500">Project Budget : </span>
													<ShowPrice :object="td" object-key="cost" label="Project Budget"></ShowPrice>
												</div>
												<!-- <div>
													<span class="fw-500">Total Invoice : </span>
													<ShowPrice
														:object="td"
														object-key="total_invoice_amount"
														label="Total Invoice"
													></ShowPrice>
												</div>
												<div>
													<span class="fw-500">Total Invoice Paid : </span>
													<ShowPrice
														:object="td"
														object-key="total_invoice_paid"
														label="Total Invoice Paid"
													></ShowPrice>
												</div> -->
											</template>
											<template v-else-if="th.type == 'project' && th.key == 'billing_address'">
												<div v-if="td.display_name">
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Display Name"
														:object="td"
														object-key="display_name"
														label="display name"
													>
													</ShowValue>
												</div>
												<div v-if="td.customer_email">
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="customer_email"
														label="email"
													>
													</ShowValue>
												</div>
												<div v-if="td.customer_phone">
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Phone Number"
														:object="td"
														object-key="customer_phone"
														label="phone number"
													>
													</ShowValue>
												</div>
												<div class="d-flex">
													<div>
														<v-icon size="16" class="mr-1">mdi-map-marker</v-icon>
													</div>
													<div style="">
														<!--max-width:200px;word-break: break-all;-->
														<p class="mb-0 text-overflow" v-if="td.customer_billing_line_1">
															{{ td.customer_billing_line_1 }},<br/>
															<template v-if="td.customer_billing_line_2">
																{{ td.customer_billing_line_2 }},
															</template>
														</p>
														<p class="mb-0 " v-if="td.customer_unit_number">
															<template v-if="td.customer_billing_unit_number"> {{ td.customer_billing_unit_number }}, </template>
															<template v-if="td.customer_billing_country">
																{{ td.customer_billing_country }},
															</template>
															{{ td.customer_billing_postal_code ? td.customer_billing_postal_code : null }}
														</p>
													</div>
												</div>
											</template>
											<template v-else-if="th.type == 'project' && th.key == 'service_address'">
												<div v-if="td.supplier_billing_address_name">
													<div>
														<v-icon small left>mdi-account</v-icon>
														<ShowValue
															tooltip
															tooltip-text="Display Name"
															:object="td"
															object-key="supplier_billing_address_name"
															label="display name"
														>
														</ShowValue>
													</div>
													<div v-if="td.supplier_billing_email">
														<v-icon small left>mdi-email</v-icon>
														<ShowValue
															tooltip
															click-to-copy
															tooltip-text="Email"
															:object="td"
															object-key="supplier_billing_email"
															label="email"
														>
														</ShowValue>
													</div>
													<div v-if="td.supplier_billing_phone_no">
														<v-icon small left>mdi-phone</v-icon>
														<ShowValue
															tooltip
															click-to-copy
															tooltip-text="Phone Number"
															:object="td"
															object-key="supplier_billing_phone_no"
															label="phone number"
														></ShowValue>
													</div>
													<div class="d-flex" v-if="td.supplier_billing_line_1">
														<div>
															<v-icon size="16" class="mr-1">mdi-map-marker</v-icon>
														</div>
														<div>
															<p class="mb-0" v-if="td.supplier_billing_line_1">
																{{ td.supplier_billing_line_1 }},
																<template v-if="td.supplier_billing_line_2">
																	{{ td.supplier_billing_line_2 }},
																</template>
															</p>
															<p class="mb-0" v-if="td.customer_shipping_address_unit_number">
																{{ td.customer_shipping_address_unit_number }},
																<template v-if="td.customer_shipping_address_country">
																	{{ td.customer_shipping_address_country }},
																</template>
																{{
																	td.customer_shipping_address_postal_code
																		? td.customer_shipping_address_postal_code
																		: null
																}}
															</p>
														</div>
													</div>
												</div>
												<em v-else class="text-muted">no service address</em>
											</template>
											<template v-else-if="th.type == 'project' && th.key == 'billing_address'">
												<div v-if="td.default_location_primary == 1">
													<v-chip small label color="green white--text text-uppercase" class="px-2">
														billing address
													</v-chip>
												</div>
												<div v-else>
													<v-chip
														small
														label
														color="blue lighten-4 blue--text text--darken-4 text-uppercase"
														class="px-2"
													>
														service location
													</v-chip>
												</div>
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Display Name"
														:object="td"
														object-key="default_location_name"
														label="display name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="default_location_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Phone Number"
														:object="td"
														object-key="default_location_phone_no"
														label="phone number"
													></ShowValue>
												</div>
												<div class="d-flex" v-if="td.default_location_address_line_1">
													<div>
														<v-icon size="16" class="mr-1">mdi-map-marker</v-icon>
													</div>
													<div>
														<p class="mb-0" v-if="td.default_location_address_line_1">
															{{ td.default_location_address_line_1 }},
															<template v-if="td.default_location_address_line_2">
																{{ td.default_location_address_line_2 }},
															</template>
														</p>
														<p class="mb-0" v-if="td.default_location_unit_number">
															{{ td.default_location_unit_number }},
															<template v-if="td.default_location_address_country">
																{{ td.default_location_address_country }},
															</template>
															{{
																td.default_location_address_postal_code
																	? td.default_location_address_postal_code
																	: null
															}}
														</p>
														&nbsp;
														<v-chip
															v-if="td.billing_address_count > 1"
															x-small
															label
															color="blue darken-4 white--text"
															class="px-1"
															@click.stop.prevent="routeToGo('billing_address', td.uuid)"
														>
															+{{ td.billing_address_count - 1 }} more
														</v-chip>
													</div>
												</div>
											</template>

											<template v-else-if="th.type == 'project' && th.key == 'customer'">
												<!-- <div>
													<v-icon small left>mdi-barcode</v-icon>
													<ShowValue tooltip click-to-copy tooltip-text="UEN " :object="td"
														object-key="uen" label="uen"></ShowValue>
												</div> -->
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														tooltip-text="display name"
														:object="td"
														object-key="display_name"
														label="display name"
													></ShowValue>
												</div>
												<!-- <div>
													<v-icon small left>mdi-home-city-outline</v-icon>
													<ShowValue tooltip tooltip-text="company name" :object="td"
														object-key="company_name" label="company name"></ShowValue>
												</div> -->
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="company email"
														:object="td"
														object-key="customer_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="company phone number"
														:object="td"
														object-key="customer_phone"
														label="phone number"
													>
													</ShowValue>
												</div>
											</template>

											<template v-else-if="th.type == 'project' && th.key == 'tasks'">
												<div class="d-flex mb-1">
													<v-avatar
														class="text-right text-10 text-white mr-2"
														size="25"
														color="red"
														text-color="white"
														>{{ td.total_pending }}
													</v-avatar>
													<span class="fw-500 mt-1">Pending </span>
												</div>
												<div class="d-flex mb-1">
													<v-avatar
														class="text-right text-10 text-white mr-2"
														size="25"
														color="blue"
														text-color="white"
														>{{ td.total_inprogress }}
													</v-avatar>
													<span class="fw-500 mt-1">In-Progress</span>
												</div>
												<div class="d-flex mb-1">
													<v-avatar
														class="text-right text-10 text-white mr-2"
														size="25"
														color="green"
														text-color="white"
														>{{ td.total_completed }}
													</v-avatar>
													<span class="fw-500 mt-1">Completed</span>
												</div>
											</template>

											<template v-else-if="th.key == 'category'">
												<v-chip
													label
													color="blue text-uppercase white--text"
													class="px-2"
													v-if="td.category_name || td.category || td.group"
												>
													<template v-if="td.category_name">
														{{ td.category_name }}
													</template>
													<template v-else-if="td.category">
														{{ td.category }}
													</template>
													<template v-else-if="td.group">
														{{ td.group }}
													</template>
												</v-chip>
												<em v-else class="text-muted">no category</em>
											</template>
											<template v-else-if="th.key == 'name'">
												<div style="min-width: 200px">
													<ShowValue
														v-if="false"
														tooltip
														tooltip-text="Serial number"
														:object="td"
														object-key="serial_number"
														label="serial number"
													>
													</ShowValue>
													<ShowValue :object="td" object-key="name" label="name"></ShowValue>
												</div>
											</template>
											<template v-else-if="th.type == 'purchase-order' && th.key == 'details'">
												<div>
													<span class="fw-500">Ref : </span>
													<ShowValue
														tooltip
														tooltip-text="reference number"
														:object="td"
														object-key="reference_number"
														label="reference number"
													></ShowValue>
												</div>
												<div>
													<span class="fw-500">Amount : </span>
													<span class="fw-600">
														<ShowPrice :object="td" object-key="total" label="amount"> </ShowPrice>
													</span>
													<v-icon v-if="false && td.is_price_changed" color="red" size="12" class="ml-1"
														>mdi-circle</v-icon
													>
												</div>
												<div>
													<span class="fw-500">Delivery Date : </span>
													<template v-if="td.date">
														{{ formatDate(td.date) }}
													</template>
													<template v-else>
														<em class="text-muted">no date</em>
													</template>
												</div>
												<div v-if="false">
													<span class="fw-500">Create Date : </span>
													<template v-if="td.added_at">
														{{ td.added_at }}
													</template>
													<template v-else>
														<em class="text-muted">no create date</em>
													</template>
												</div>
											</template>
											<template
												v-else-if="
													(th.type == 'purchase-order' ||
														th.type == 'quotation' ||
														th.type == 'claim-expense') &&
													th.key == 'customer_name'
												"
											>
												<div>
													<v-icon small left>mdi-home-city-outline</v-icon>
													<ShowValue
														tooltip
														tooltip-text="company name"
														:object="td"
														object-key="customer_company_name"
														label="company name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														tooltip-text="display name"
														:object="td"
														object-key="customer_name"
														label="display name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="customer_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="phone number"
														:object="td"
														object-key="customer_phone"
														label="phone number"
													>
													</ShowValue>
												</div>
											</template>
											<template
												v-else-if="
													(th.type == 'purchase-order' ||
														th.type == 'quotation' ||
														th.type == 'claim-expense') &&
													th.key == 'customer_contact_person'
												"
											>
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														tooltip-text="display name"
														:object="td"
														object-key="customer_contact_person_display_name"
														label="display name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="customer_contact_person_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="phone number"
														:object="td"
														object-key="customer_contact_person_phone_number"
														label="phone number"
													>
													</ShowValue>
												</div>
											</template>
											<template v-else-if="th.type == 'purchase-order' && th.key == 'supplier_name'">
												<div>
													<v-icon small left>mdi-home-city-outline</v-icon>
													<ShowValue
														tooltip
														tooltip-text="company name"
														:object="td"
														object-key="supplier_company_name"
														label="company name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														tooltip-text="display name"
														:object="td"
														object-key="supplier_name"
														label="display name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="supplier_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="phone number"
														:object="td"
														object-key="supplier_phone"
														label="phone number"
													>
													</ShowValue>
												</div>
											</template>
											<template v-else-if="th.type == 'purchase-order' && th.key == 'supplier_contact_person'">
												<div>
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														tooltip-text="display name"
														:object="td"
														object-key="supplier_contact_person_display_name"
														label="display name"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td"
														object-key="supplier_contact_person_email"
														label="email"
													></ShowValue>
												</div>
												<div>
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="phone number"
														:object="td"
														object-key="supplier_contact_person_phone_number"
														label="phone number"
													>
													</ShowValue>
												</div>
											</template>
											<template
												v-else-if="
													(th.type == 'purchase-order' ||
														th.type == 'quotation' ||
														th.type == 'claim-expense') &&
													th.key == 'project_name'
												"
											>
												<div :style="`min-width:${th.width}px`">
													<template
														v-if="
															td.project_name ||
															td.project_price ||
															td.project_status_value ||
															td.project_start_date ||
															td.project_end_date
														"
													>
														<div :style="`width:${th.width}px;`" class="text-truncate">
															<span class="fw-500">Name : </span>
															<ShowValue
																truncate
																:object="td"
																object-key="project_name"
																label="project name"
															></ShowValue>
														</div>
														<div class="d-flex">
															<span>
																<span class="fw-500">Price : </span>
																<span class="fw-600">
																	<ShowPrice
																		:object="td"
																		object-key="project_price"
																		label="project cost"
																	></ShowPrice>
																</span>
															</span>
															<v-spacer></v-spacer>
															<v-tooltip top v-if="false">
																<template v-slot:activator="{ on, attrs }">
																	<v-icon
																		v-on="on"
																		v-bind="attrs"
																		class="text-right"
																		size="16"
																		:color="getStatusColor(td.project_status_value)"
																		>mdi-circle</v-icon
																	>
																</template>
																<span
																	>Project Status -
																	<span class="text-uppercase">{{ td.project_status_value }}</span></span
																>
															</v-tooltip>
														</div>
														<div>
															<span class="fw-500">Start : </span>
															<template v-if="td.project_start_date">
																{{ formatDate(td.project_start_date) }}
															</template>
															<template v-else>
																<em class="text-muted">no start date</em>
															</template>
														</div>
														<div>
															<span class="fw-500">End : </span>
															<template v-if="td.project_end_date">
																{{ formatDate(td.project_end_date) }}
															</template>
															<template v-else>
																<em class="text-muted">no end date</em>
															</template>
														</div>
														<div>
															<span class="fw-500">Status : </span>
															<v-tooltip top>
																<template v-slot:activator="{ on, attrs }">
																	<v-chip
																		:color="getStatusColor(td.project_status_value)"
																		label
																		small
																		v-on="on"
																		v-bind="attrs"
																		class="white--text"
																	>
																		<span class="text-uppercase">
																			{{ td.project_status_value }}
																		</span>
																	</v-chip>
																</template>
																<span>Project Status</span>
															</v-tooltip>
														</div>
													</template>
													<em v-else class="text-muted">no project</em>
												</div>
											</template>
											<template v-else-if="th.type == 'quotation' && th.key == 'billing_address'">
												<div v-if="td.customer_default_address">
													<v-icon small left>mdi-account</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Display Name"
														:object="td.customer_default_address"
														object-key="display_name"
														label="display name"
													></ShowValue>
												</div>
												<div v-if="td.customer_default_address">
													<v-icon small left>mdi-email</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Email"
														:object="td.customer_default_address"
														object-key="email"
														label="email"
													></ShowValue>
												</div>
												<div v-if="td.customer_default_address">
													<v-icon small left>mdi-phone</v-icon>
													<ShowValue
														tooltip
														click-to-copy
														tooltip-text="Phone Number"
														:object="td.customer_default_address"
														object-key="phone_number"
														label="phone number"
													></ShowValue>
												</div>
												<div
													class="d-flex "
													v-if="td.customer_default_address && td.customer_default_address.address_line_1"
												>
													<div>
														<v-icon size="16" class="mr-1">mdi-map-marker</v-icon>
													</div>
													<div>
														<p
															class="mb-0 text-overflow"
															v-if="td.customer_default_address && td.customer_default_address.address_line_1"
														>
															{{ td.customer_default_address.address_line_1 }},
															<template
																v-if="td.customer_default_address && td.customer_default_address.address_line_2"
															>
																{{ td.customer_default_address.address_line_2 }},
															</template>
														</p>
														<p class="mb-0 text-overflow" v-if="td.customer_default_address">
															<template
																v-if="td.customer_default_address && td.customer_default_address.unit_number"
															>
																{{ td.customer_default_address.unit_number }},
															</template>
															<template
																v-if="td.customer_default_address && td.customer_default_address.address_country"
															>
																{{ td.customer_default_address.address_country }},
															</template>
															{{
																td.customer_default_address && td.customer_default_address.address_postal_code
																	? td.customer_default_address.address_postal_code
																	: null
															}}
														</p>
													</div>
												</div>
											</template>
											<template v-else-if="th.type == 'quotation' && th.key == 'service_address'">
												<div v-if="td.customer_shipping_address_line_1">
													<div>
														<v-icon small left>mdi-account</v-icon>
														<ShowValue
															tooltip
															tooltip-text="Display Name"
															:object="td"
															object-key="customer_shipping_address_name"
															label="display name"
														></ShowValue>
													</div>
													<div>
														<v-icon small left>mdi-email</v-icon>
														<ShowValue
															tooltip
															click-to-copy
															tooltip-text="Email"
															:object="td"
															object-key="customer_shipping_address_email"
															label="email"
														>
														</ShowValue>
													</div>
													<div>
														<v-icon small left>mdi-phone</v-icon>
														<ShowValue
															tooltip
															click-to-copy
															tooltip-text="Phone Number"
															:object="td"
															object-key="customer_shipping_address_phone_number"
															label="phone number"
														></ShowValue>
													</div>
													<div class="d-flex" v-if="td.customer_shipping_address_line_1">
														<div>
															<v-icon size="16" class="mr-1">mdi-map-marker</v-icon>
														</div>
														<div>
															<p class="mb-0 text-overflow" v-if="td.customer_shipping_address_line_1">
																{{ td.customer_shipping_address_line_1 }},
																<template v-if="td.customer_shipping_address_line_2">
																	{{ td.customer_shipping_address_line_2 }},
																</template>
															</p>
															<p class="mb-0" v-if="td.supplier_billing_unit_number">
																{{ td.supplier_billing_unit_number }},
																<template v-if="td.supplier_billing_country">
																	{{ td.supplier_billing_country }},
																</template>
																{{
																	td.customer_shipping_address_postal_code
																		? td.customer_shipping_address_postal_code
																		: null
																}}
															</p>
														</div>
													</div>
												</div>
												<em v-else class="text-muted">no service address</em>
											</template>
											<template v-else-if="th.type == 'quotation' && th.key == 'details'">
												<div class="flex-wrap">
													<div :style="`max-width:${th.width}px`" class="d-flex">
														<span class="fw-500 mr-1">Title:</span>
														<span class="fw-400 text-truncate d-block">
															<ShowValue :object="td" object-key="title" label="title"> </ShowValue>
														</span>
													</div>
													<div>
														<span class="fw-500 mr-1">Reference:</span>
														<span class="fw-400">
															<ShowValue :object="td" object-key="reference" label="reference"></ShowValue>
														</span>
													</div>
													<div>
														<span class="fw-500 mr-1">Prepared by:</span>
														<span class="fw-400">
															<ShowValue :object="td" object-key="prepared_by" label="prepared by"></ShowValue>
														</span>
													</div>
													<div>
														<span class="fw-500 mr-1">Staff Person:</span>
														<span class="fw-400">
															<ShowValue :object="td" object-key="sales_person" label="Staff Person"></ShowValue>
														</span>
													</div>
													<div>
														<span class="fw-500">Amount : </span>
														<span class="fw-600">
															<ShowPrice :object="td" object-key="total" label="amount"> </ShowPrice>
														</span>
														<v-icon v-if="false && td.is_price_changed" color="red" size="12" class="ml-1"
															>mdi-circle</v-icon
														>
													</div>
												</div>
											</template>
											<template v-else-if="th.type == 'claim-expense' && th.key == 'budget_category'">
												<template
													v-if="td.budgets_name || td.budgets_cost || td.sub_budgets_name || td.sub_budgets_cost"
												>
													<div :style="`width:${th.width}px;`" class="text-truncate">
														<span class="fw-500">budgets : </span>
														<ShowValue
															truncate
															:object="td"
															object-key="budgets_name"
															label="budgets"
														></ShowValue>
													</div>
													<div>
														<span class="fw-500">budgets cost : </span>
														<span class="fw-600">
															<ShowPrice :object="td" object-key="budgets_cost" label="budgets cost"></ShowPrice>
														</span>
													</div>
													<div :style="`width:${th.width}px;`" class="text-truncate" v-if="td.sub_budget_name">
														<span class="fw-500">sub budget : </span>
														<ShowValue
															truncate
															:object="td"
															object-key="sub_budget_name"
															label="sub budgets"
														></ShowValue>
													</div>
													<div v-if="td.sub_budget_cost">
														<span class="fw-500">sub budget cost : </span>
														<span class="fw-600">
															<ShowPrice
																:object="td"
																object-key="sub_budget_cost"
																label="sub budgets cost"
															></ShowPrice>
														</span>
													</div>
												</template>
												<em v-else class="text-muted">no budget</em>
											</template>
											<template v-else-if="th.type == 'quotation' && th.key == 'terms_and_conditions'">
												<v-col md-12>
													<ShowValue
														:object="td"
														object-key="terms_and_conditions_text"
														label="terms conditions"
													></ShowValue>
												</v-col>
											</template>

											<template v-else-if="th.type == 'quotation' && th.key == 'deadline'">
												<v-col md-12>
													<ShowValue :object="td" object-key="deadline_text" label="deadline"> </ShowValue>
												</v-col>
											</template>

											<template v-else-if="th.key == 'invoice_detail'">
												<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
													<div>
														<Chip
															tooltip
															tooltip-text="Invoice #"
															small
															:text="td.barcode"
															color="blue darken-4"
														></Chip>
													</div>
													<div>
														<Status
															tooltip
															tooltip-text="Invoice Status"
															small
															:status="td.status_value"
															class="mt-1"
														></Status>
													</div>
													<div>
														<span class="bold-600 mr-1">Invoice Number:</span>
														<ShowValue :object="td" object-key="invoice_id" label="invoice number"></ShowValue>
													</div>
												</div>
											</template>

											<template v-else-if="th.key == 'payment_detail'">
												<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
													<Chip
														tooltip
														tooltip-text="Payment #"
														small
														:text="td.barcode"
														color="blue darken-4"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Payment Mode"
														small
														class="mt-1"
														:text="td.p_mode"
														color="amber accent-4"
													></Chip>
													<div class="mt-1">
														<span class="bold-600 mr-1">Amount Received:</span>
														<ShowValue
															:object="td"
															object-key="p_amount_formatted"
															label="amount received"
														></ShowValue
														><br />
														<span class="bold-600 mr-1">Payment Date:</span>
														<ShowValue
															:object="td"
															object-key="p_date_formatted"
															label="payment date"
														></ShowValue>
													</div>
												</div>
											</template>

											<template v-else-if="th.key == 'payment_invoice_detail'">
												<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
													<Chip
														tooltip
														tooltip-text="Invoice #"
														small
														:text="td.invoice_barcode"
														color="blue darken-4"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Invoice Status"
														small
														class="mt-1"
														:text="td.invoice_status_text"
														:color="td.invoice_status_color"
														:text-color="td.invoice_status_textcolor"
													></Chip>
													<div>
														<span class="bold-600 mr-1">Invoice Number:</span>
														<ShowValue
															:object="td"
															object-key="invoice_invoice_id"
															label="invoice number"
														></ShowValue>
													</div>
												</div>
											</template>

											<template v-else-if="th.key == 'invoice_approval'">
												<div class="d-flex align-center my-1" v-if="td.is_approved == 1">
													<div v-if="false">
														<ImageTemplate
															circle
															style="max-width: 50px"
															:src="td.approve_by_profile"
														></ImageTemplate>
													</div>
													<div class="ml-2">
														<p class="mb-0">
															<Chip
																tooltip
																tooltip-text="Approval Status"
																small
																:text="td.approve_status_formatted"
																:color="
																	td.approve_status == 1
																		? 'green lighten-1 white--text'
																		: 'red lighten-1 white--text'
																"
															></Chip>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Approved By:</span> {{ td.approve_by_name }}
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Approved At:</span> {{ td.approve_at_formatted }}
														</p>
													</div>
												</div>
												<div v-else class="text-center text--secondary">N/A</div>
											</template>

											<template v-else-if="th.key == 'service_detail'">
												<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
													<Chip
														tooltip
														tooltip-text="Service #"
														small
														:text="td.service_barcode"
														color="blue darken-4"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Service Type"
														small
														:text="td.service_type_formatted"
														class="mt-1"
														:color="td.service_type == 'on-site' ? 'brown darken-4' : 'blue-grey darken-4'"
													>
													</Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'supplier'">
												<div class="d-flex align-items-center">
													<template v-if="getFilteredSupplers(td.suppliers).length">
														<template v-for="(row, index) in getFilteredSupplers(td.suppliers)">
															<v-tooltip top content-class="custom-top-tooltip" :key="'tooltip' + index">
																<template v-slot:activator="{ on, attrs }">
																	<v-avatar
																		v-bind="attrs"
																		v-on="on"
																		v-if="index < 2"
																		color="blue lighten-4"
																		size="40"
																		:style="`margin-left:${
																			index > 0 ? '-12px' : ''
																		};border:1px solid #0000002e !important`"
																	>
																		<template v-if="row && row.supplier_image">
																			<v-img :src="row.supplier_image"></v-img>
																		</template>
																		<span v-else style="font-size: 14px" class="">
																			{{ getTwoCharacter(row && row.supplier_name) }}
																		</span>
																	</v-avatar>
																</template>
																<span style="font-size: 11px">{{ row.supplier_name }}</span>
															</v-tooltip>
															<v-chip
																v-if="index == 2"
																:key="'span' + index"
																x-small
																label
																color="blue darken-4 white--text"
																class="px-1"
																@click.stop.prevent="routeToGo('suppliers', td.uuid)"
															>
																+{{ td.suppliers.length - 2 }} more
															</v-chip>

															<!-- ({{ td.suppliers.length - 2 }} more)</span> -->
														</template>
													</template>
													<template v-else>
														<span>
															<em class="text-muted">No supplier</em>
														</span>
													</template>
												</div>
											</template>
											<template
												v-else-if="
													th.key == 'terms_and_conditions_text' || th.key == 'description' || th.key == 'remark'
												"
											>
												<span class="line-clamp-2" :style="`min-width:${th.width}px`">
													<ShowTextAreaValue :object="td" :object-key="th.key" :label="th.name">
													</ShowTextAreaValue>
												</span>
											</template>

											<template v-else-if="th.type == 'equipment' && th.key == 'equipment_detail'">
												<div class="flex-wrap">
													<div class="d-flex" :style="`width:${th.width}px`">
														<span class="fw-500 mr-1">Name:</span>
														<span class="fw-400 text-truncate d-block">
															<ShowValue truncate :object="td" object-key="name" label="name"> </ShowValue>
														</span>
													</div>

													<div>
														<span class="fw-500 mr-1">category:</span>
														<span class="fw-400">
															<ShowValue :object="td" object-key="category" label="category"> </ShowValue>
														</span>
													</div>
												</div>
											</template>
											<template v-else-if="th.type == 'equipment' && th.key == 'password'">
												<div class="flex-wrap">
													<div class="d-flex" :style="`width:${th.width}px`">
														<span class="fw-400 text-truncate d-block">
															<ShowValue truncate :object="td" object-key="password" label="password"> </ShowValue>
														</span>
													</div>
												</div>
											</template>
											<template v-else-if="th.type == 'equipment' && th.key == 'purchased_info'">
												<div class="flex-wrap">
													<div class="d-flex" :style="`width:${th.width}px`">
														<span class="fw-500 mr-1">Purchased On:</span>
														<span class="fw-400 text-truncate d-block">
															<ShowValue truncate :object="td" object-key="purchased_on" label="Purchased On">
															</ShowValue>
														</span>
													</div>

													<div>
														<span class="fw-500 mr-1">Cost Price:</span>
														<span class="fw-400">
															<ShowValue :object="td" object-key="cost_price" label="Cost Price"> </ShowValue>
														</span>
													</div>
													<div v-if="td.supplier">
														<span class="fw-500 mr-1">Supplier:</span>
														<span class="fw-400"
															><v-icon size="18">mdi-account-circle</v-icon>
															<ShowValue :object="td" object-key="supplier" label="Supplier"> </ShowValue>
														</span>
													</div>
												</div>
											</template>

											<template v-else-if="th.type == 'equipment' && th.key == 'warranty_info'">
												<div v-if="td.warranty_start_date" class="d-flex align-items-center">
													<div
														:class="`ml-2 ${
															td.isWarrantyExpired == 1 ? 'expired' : td.isWarrantyRenewed == 1 ? 'renewed' : ''
														}`"
													>
														<div>
															<v-tooltip top content-class="custom-top-tooltip">
																<template v-slot:activator="{ on, attrs }">
																	<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
																		<v-badge color="cyan" content="S" class="badge-new"></v-badge>
																	</label>
																</template>
																<span> Start Date </span>
															</v-tooltip>
															<v-chip label class="mb-2">
																{{ td.warranty_start_date }}
															</v-chip>
														</div>

														<div>
															<v-tooltip top content-class="custom-top-tooltip">
																<template v-slot:activator="{ on, attrs }">
																	<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
																		<v-badge color="red" content="E" class="badge-new"></v-badge>
																	</label>
																</template>
																<span> End Date </span>
															</v-tooltip>
															<v-chip label class="mb-2">
																{{ td.warranty_end_date }}
															</v-chip>
														</div>
														<div>
															<v-tooltip top content-class="custom-top-tooltip">
																<template v-slot:activator="{ on, attrs }">
																	<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
																		<v-badge color="orange" content="R" class="badge-new"></v-badge>
																	</label>
																</template>
																<span> Reminder Date </span>
															</v-tooltip>
															<v-chip label class="mb-2">
																{{ td.warranty_reminder_date }}
															</v-chip>
														</div>
													</div>
												</div>
												<div v-else>
													<span class="text-truncate" v-html="'No Warranty Data'"> </span>
												</div>
											</template>

											<template v-else-if="th.type == 'inventory' && th.key == 'product_detail'">
												<div class="flex-wrap">
													<div class="d-flex" :style="`width:${th.width}px`">
														<span class="fw-500 mr-1">Name:</span>
														<span class="fw-400 text-truncate d-block">
															<ShowValue truncate :object="td" object-key="name" label="name"> </ShowValue>
														</span>
													</div>
													<div>
														<span class="fw-500 mr-1">Serial No:</span>
														<span class="fw-400">
															<ShowValue
																click-to-copy
																tooltip
																tooltip-text="click to copy"
																:object="td"
																object-key="serial_number"
																label="serial no"
															>
															</ShowValue>
														</span>
													</div>
													<div>
														<span class="fw-500 mr-1">category:</span>
														<span class="fw-400">
															<ShowValue :object="td" object-key="group" label="category"> </ShowValue>
														</span>
													</div>
												</div>
											</template>
											<template v-else-if="th.type == 'inventory' && th.key == 'pricing_detail'">
												<div class="flex-wrap">
													<!-- <div>
														<span class="fw-500 mr-1">Unit Price:</span>
														<span class="fw-600">
															<ShowValue
																:object="td"
																object-key="unit_price_formatted"
																label="unit price"
															></ShowValue>
														</span>
													</div> -->
													<div>
														<span class="fw-500 mr-1">Selling Price/Unit Price:</span>
														<span class="fw-600">
															<ShowValue
																:object="td"
																object-key="selling_cost_formatted"
																label="selling srice/unit price"
															></ShowValue>
														</span>
													</div>
													<div>
														<span class="fw-500 mr-1">Discounted Price:</span>
														<span class="fw-600">
															<ShowValue
																:object="td"
																object-key="discount_price_formatted"
																label="discounted Price"
															></ShowValue>
														</span>
													</div>
												</div>
											</template>
											<template v-else-if="th.type == 'inventory' && th.key == 'extended_information'">
												<div class="flex-wrap">
													<div>
														<span class="fw-500 mr-1">Available Stock:</span>
														<span class="fw-600">
															<ShowValue
																:object="td"
																object-key="available_stock"
																label="available_stock"
															></ShowValue>
														</span>
													</div>
													<div>
														<span class="fw-500 mr-1">Reorder Level:</span>
														<span class="fw-600">
															<ShowValue
																:object="td"
																object-key="reorder_quantity"
																label="reorder level"
															></ShowValue>
														</span>
													</div>
													<div>
														<span class="fw-500 mr-1">manufacturer:</span>
														<span class="fw-600">
															<ShowValue :object="td" object-key="manufacturer" label="manufacture"></ShowValue>
														</span>
													</div>
												</div>
											</template>
											<template v-else-if="th.key == 'service_expected_duration'">
												<div class="d-flex flex-wrap">
													<div>
														<span class="bold-600 mr-1">Start:</span>
														<ShowValue
															:object="td"
															object-key="expected_start_date_formatted"
															label="Start Date (Expected)"
														></ShowValue>
													</div>
													<div>
														<span class="bold-600 mr-1">End:</span>
														<ShowValue
															:object="td"
															object-key="expected_end_date_formatted"
															label="End Date (Expected)"
														></ShowValue>
													</div>
												</div>
											</template>
											<template v-else-if="th.key == 'service_actual_duration'">
												<div class="d-flex flex-wrap" v-if="td.actual_start_date_formatted">
													<div>
														<span class="bold-600 mr-1">Start:</span>
														<ShowValue
															:object="td"
															object-key="actual_start_date_formatted"
															label="Start Date (Actual)"
														></ShowValue>
													</div>
													<div>
														<span class="bold-600 mr-1">End:</span>
														<ShowValue
															:object="td"
															object-key="actual_end_date_formatted"
															label="End Date (Actual)"
														></ShowValue>
													</div>
												</div>
												<div v-else class="text-center text--secondary">N/A</div>
											</template>
											<template v-else-if="th.key == 'service_type'">
												<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
													<Chip
														tooltip
														tooltip-text="Service Type"
														small
														:text="td.service_type_formatted"
														:color="td.service_type == 'on-site' ? 'brown darken-4' : 'blue-grey darken-4'"
													>
													</Chip>
													<Chip
														tooltip
														tooltip-text="Invoiced"
														small
														v-if="td.is_invoiced == 1"
														class="mt-1"
														text="Invoiced"
														color="orange darken-2"
													></Chip>
													<Status
														tooltip
														tooltip-text="Status"
														small
														:status="td.status_value"
														class="mt-1"
													></Status>
												</div>
											</template>
											<template v-else-if="th.key == 'asset_service'">
												<div class="d-flex align-center">
													<div>
														<ImageTemplate circle style="max-width: 50px" :src="td.asset_image"> </ImageTemplate>
													</div>
													<div class="ml-2">
														<p class="mb-0">
															<Chip
																tooltip
																tooltip-text="Asset #"
																small
																:text="td.asset_barcode"
																color="blue darken-4"
															></Chip>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Asset Name:</span>
															<ShowValue :object="td" object-key="asset_name" label="asset name"></ShowValue>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Asset Id:</span>
															<ShowValue :object="td" object-key="asset_id" label="asset id"> </ShowValue>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Cost Center:</span>
															<ShowValue
																:object="td"
																object-key="asset_cost_center"
																label="cost center"
															></ShowValue>
														</p>
													</div>
												</div>
											</template>
											<template v-else-if="th.key == 'acknowledgement'">
												<div class="d-flex align-center my-1" v-if="td.is_acknowledged == 1">
													<div v-if="false">
														<ImageTemplate
															circle
															style="max-width: 50px"
															:src="td.acknowledge_by_profile"
														></ImageTemplate>
													</div>
													<div class="ml-2">
														<p class="mb-0">
															<Chip
																tooltip
																tooltip-text="Acknowledge Status"
																small
																:text="td.acknowledge_status_formatted"
																:color="
																	td.acknowledge_status == 1
																		? 'green lighten-1 white--text'
																		: 'red lighten-1 white--text'
																"
															></Chip>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Acknowledge By:</span>{{ td.acknowledge_by_name }}
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Acknowledge At:</span>{{ td.acknowledge_at_formatted }}
														</p>
													</div>
												</div>
												<div v-else class="text-center text--secondary">N/A</div>
											</template>
											<template v-else-if="th.key == 'service_performed_by'">
												<div class="d-flex align-center">
													<div v-if="false">
														<ImageTemplate
															circle
															style="max-width: 50px"
															:src="td.perfomed_by_profile"
														></ImageTemplate>
													</div>
													<div class="ml-2">
														<p class="mb-0">
															<Chip
																tooltip
																tooltip-text="Service By"
																small
																:text="td.service_by_formatted"
																color="blue darken-4 white--text"
															></Chip>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Service By:</span>{{ td.perfomed_by_display_name }}
														</p>
													</div>
												</div>
											</template>
											<template v-else-if="th.key == 'created_at'">
												<div>
													<Chip
														tooltip
														tooltip-text="Created By"
														small
														:text="td.created_by"
														color="blue darken-4 white--text"
														class="mb-1"
													></Chip>
												</div>
												<div v-if="th.type == 'request-po' && td.status_value == 'approved'">
													<Chip
														tooltip
														tooltip-text="Approve By"
														small
														:text="td.approve_by"
														color="green darken-4 white--text"
														class="mb-1"
													></Chip>
												</div>
												<TableDateTime
													small
													v-if="td.created_at"
													:human-format="td.added_at"
													:system-format="td.created_at"
												></TableDateTime>
											</template>
											<template v-else-if="th.key == 'modified_at'">
												<div>
													<Chip
														v-if="td.modified_by"
														tooltip
														tooltip-text="Modified By"
														small
														:text="td.modified_by"
														color="blue darken-4 white--text"
														class="mb-1"
													></Chip>
												</div>
												<TableDateTime
													small
													v-if="td.modified_at"
													:human-format="td.updated_at"
													:system-format="td.modified_at"
												></TableDateTime>
												<em v-else>no modified</em>
											</template>
											<template v-else-if="th.key == 'description'">
												<div class="text-truncate" :style="`width:${th.width}px`">
													{{ td.description }}
												</div>
											</template>
											<template v-else>
												<span class="line-clamp-2">
													<ShowValue :object="td" :object-key="th.key" :label="th.name"> </ShowValue>
												</span>
											</template>
										</td>
									</template>
								</tr>
							</v-hover>
						</template>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="11">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no {{ type=='claim-expense' ? 'expenses' : type }} at the moment.
								</p>
							</td>
						</tr>
					</tfoot>
				</template>
			</v-simple-table>
			<v-row class="my-2">
				<v-col md="6" class="my-auto">
					<p class="m-0">{{ showingString }}</p>
				</v-col>
				<v-col md="6" class="text-right">
					<v-pagination
						color="blue darken-4"
						v-model="page"
						:length="totalPage"
						total-visible="9"
						v-on:input="updatePagination($event)"
					></v-pagination>
				</v-col>
			</v-row>
		</template>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<Dialog :dialog="deleteDialog" :dialog-width="640">
			<template v-slot:title> Delete {{ type }}</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							<span class="text-capitalize">{{ type }} </span>
							<span class="red--text font-bolder lighten-1">#{{ deleteText }}</span>
							will be deleted forever and cannot be retrieved later. Are you sure about deleting it?
						</p>
					</v-col>
					<v-col v-if="deleteNote" md="12" class="pb-0 mt-2 my-auto">
						<em class="red--text">Note: {{ deleteNote }}</em>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="deleteButton"
					:disabled="deleteButton"
					depressed
					color="red lighten-1"
					tile
					v-on:click="deleteRow"
				>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile :disabled="deleteButton" v-on:click="deleteClear"> No, Close </v-btn>
			</template>
		</Dialog>
	</v-sheet>
</template>
<script>
import { toSafeInteger, isArray, filter, find } from "lodash";
import { mapGetters } from "vuex";
import { SET_SELECTED_ROWS, SET_CURRENT_PAGE } from "@/core/services/store/listing.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import Chip from "@/view/components/Chip";
import Status from "@/view/components/Status";
import ShowPrice from "@/view/components/ShowPrice";
import Dialog from "@/view/components/Dialog";
import TableDateTime from "@/view/components/TableDateTime";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import ImageTemplate from "@/view/components/Image";
import { /* CART_COUNT, */ SET_LOCAL_DB } from "@/core/services/store/config.module";
import { getToken } from "@/core/services/jwt.service.js";
import { getData, saveData, deleteData } from "@/core/services/local.service";
export default {
	name: "table-template",
	props: {
		type: {
			type: String,
			default: null,
		},
		deleteEndpoint: {
			type: String,
			default: null,
		},
		detailRoute: {
			type: String,
			default: null,
		},
		updateRoute: {
			type: String,
			default: null,
		},
		deleteNote: {
			type: String,
			default: null,
		},
		dialogUpdate: {
			type: Boolean,
			default: false,
		},
		disableEdit: {
			type: Boolean,
			default: false,
		},
		dialogView: {
			type: Boolean,
			default: false,
		},
		internal: {
			type: Boolean,
			default: false,
		},
		pageLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			checkbox: false,
			indeterminate: false,
			selectedRows: [],
			deleteDialog: false,
			deleteButton: false,
			deleteUUID: null,
			deleteURL: null,
			deleteText: null,
			page: 1,
			localData: null,
			deleteLocalStorageID: null,
			localStorageKey: null,
		};
	},
	watch: {
		currentPage(param) {
			this.page = param;
		},
		
		selected(param) {
			if (param.length < 1) {
				this.checkbox = false;
				this.indeterminate = false;
				this.selectedRows = [];
			}
		},
	},
	methods: {
		is_array(data) {
			return isArray(data);
		},
		getPrimaryPeson(data) {
			if (isArray(data)) {
				return find(data, (row) => row.primary);
			}
			return {};
		},
		getBarcodeTooltipText(text) {
			if (text == "vendor") {
				return "supplier #";
			}
			if (text == "inventory") {
				return "product #";
			}
			return text + " #";
		},
		RequestPoDetail(uuid) {
			this.$router.replace({
				name: "request-po-detail",
				params: { uuid },
				query: { t: new Date().getTime() },
			});
		},

		is_invoice_disabled(row) {
			if (this.type == "service") {
				if (row.is_invoiced == 1) {
					return true;
				}
				if (row.is_acknowledged == 1) {
					if (row.acknowledge_status == 1) {
						return false;
					}
				}
			}
			return true;
		},
		convert_to_invoice(row) {
			this.$router.push({
				name: "invoice-create",
				query: { service: row.uuid, asset: row.asset_uuid, t: new Date().getTime() },
			});
		},
		is_payment_disabled(row) {
			if (this.type == "invoice") {
				if (row.is_paid == 1) {
					return true;
				}
				if (row.is_approved == 1) {
					if (row.approve_status == 1) {
						return false;
					}
				}
			}
			return true;
		},
		convert_to_payment(row) {
			this.$router.push({
				name: "payment-create",
				query: { invoice: row.uuid, t: new Date().getTime() },
			});
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.$emit("reload:content", true);
			});
		},
		downloadFile(row) {
			window.open(row.url, "_blank");
		},
		downloadPdf(param, type) {
			if (type == "order-receive-pdf") {
				if (!param.has_checkin_pdf) {
					return false;
				}
			}
			if (type == "order-issue-pdf") {
				if (!param.has_checkout_pdf) {
					return false;
				}
			}
			if (type == "order-retire-pdf") {
				if (!param.has_retire_pdf) {
					return false;
				}
			}

			const token = getToken();
			const url = `${this.$apiURL()}${type}/${param.id}/download?token=${token}`;
			window.open(url, "_blank");
		},
		routeToDetail(order, uuid) {
			console.log(uuid);
			if (order < 2) {
				return false;
			}
			if (!this.dialogView) {
				if (this.detailRoute) {
					if (this.internal) {
						let routeData = this.$router.resolve({
							name: this.detailRoute,
							params: { uuid },
							query: { t: new Date().getTime() },
						});
						window.open(routeData.href, "_blank");
					} else {
						this.$router.push({
							name: this.detailRoute,
							params: { uuid },
							query: { t: new Date().getTime() },
						});
					}
				}
			} else {
				this.$emit("view:dialog", uuid);
				return false;
			}
		},
		routeToContactPerson(uuid) {
			this.$router.push({
				name: this.detailRoute,
				params: { uuid },
				query: { t: new Date().getTime(), tab: "contact_person" },
			});
		},
		routeToGo(tab, uuid) {
			switch (tab) {
				case "billing_address":
					this.$router.push({
						name: this.detailRoute,
						params: { uuid },
						query: { t: new Date().getTime(), tab: "address-book" },
					});
					break;
				/* case "suppliers":
					this.$router.push({
						name: this.detailRoute,
						params: { uuid },
						query: { t: new Date().getTime(), tab: "suppliers" },
					});
					break; */
				case "contact_person":
					if (this.type == "customer") {
						this.$router.push({
							name: this.detailRoute,
							params: { uuid },
							query: { t: new Date().getTime(), expansion: "contact-person" },
						});
					}
					if (this.type == "supplier") {
						this.$router.push({
							name: this.detailRoute,
							params: { uuid },
							query: { t: new Date().getTime(), tab: "contact-persons" },
						});
					}
					break;

				default:
					this.$router.push({
						name: this.detailRoute,
						params: { uuid },
						query: { t: new Date().getTime(), tab: tab },
					});
					break;
			}
		},
		routeToUpdate(uuid) {
			
			if (this.dialogUpdate) {
				this.$emit("update:dialog", uuid);
				return false;
			}
			this.$router.push({
				name: this.updateRoute,
				params: { uuid },
				query: { t: new Date().getTime() },
			});
		},
		deleteClear() {
			this.deleteDialog = false;
			this.deleteUUID = null;
			this.deleteURL = null;
			this.deleteText = null;
		},
		deleteRow() {
			if (this.$route.name === "supplier") {
				this.localStorageKey = "create_product_supplier";
				this.localData = getData("create_product_supplier");
				if (this.localData && isArray(this.localData)) {
					this.localData = filter(this.localData, (item) => {
						return this.deleteLocalStorageID !== item.supplier_id;
					});
					if (!this.localData.length) {
						deleteData("create_product_supplier");
					} else {
						saveData(this.localStorageKey, this.localData);
					}
					this.deleteLocalStorageID = null;
					this.localData = null;
					this.localStorageKey = null;
				}
			}
			this.deleteButton = true;
			ApiService.patch(this.deleteURL)
				.then(() => {
					this.$emit("reload:content");
					// this.$store.dispatch(CART_COUNT);
					this.$store.dispatch(SET_LOCAL_DB);
					this.deleteClear();
				})
				.catch(({ response }) => {
					console.log({ response });
				})
				.finally(() => {
					this.deleteButton = false;
				});
		},
		deleteConfirm({ barcode, uuid, id }) {
			this.deleteDialog = true;
			this.deleteUUID = uuid;
			this.deleteLocalStorageID = id;
			this.deleteURL = this.deleteEndpoint + uuid;
			this.deleteText = barcode;
		},
		copyBarcode(barcode) {
			navigator.clipboard.writeText(barcode);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${barcode}</b> - successfully coppied.` },
			]);
		},
		getValue(td, key) {
			const key_array = key.split(".");
			if (key_array.length > 1) {
				if (key_array[3]) {
					return td[key_array[0]][key_array[1]][key_array[2]][key_array[3]];
				}
				if (key_array[2]) {
					return td[key_array[0]][key_array[1]][key_array[2]];
				}
				if (key_array[1]) {
					return td[key_array[0]][key_array[1]];
				}
				if (key_array[0]) {
					return td[key_array[0]];
				}
			}
			return td[key] || "-";
		},
		updateSelected() {
			if (this.selectedRows && isArray(this.selectedRows)) {
				const selectedRowsLength = toSafeInteger(this.selectedRows.length);
				const tbodyLength = toSafeInteger(this.tbody.length);
				if (selectedRowsLength > 0) {
					if (!this.checkbox) {
						this.checkbox = true;
					}
					this.indeterminate = false;
					if (selectedRowsLength != tbodyLength) {
						this.indeterminate = true;
					}
				} else {
					this.selectedRows = [];
					this.checkbox = false;
					this.indeterminate = false;
				}
				this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
			} else {
				this.$store.commit(SET_SELECTED_ROWS, []);
			}
		},
		selectAll() {
			this.selectedRows = [];
			if (this.checkbox) {
				for (let i = 0; i < this.tbody.length; i++) {
					this.selectedRows.push(this.tbody[i].uuid);
				}
			}
			this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
		},
		createCheckInOut(data) {
			this.$emit("createCheckInOut", data);
		},
		is_edit_disabled(row) {
			
			if (this.type == "invoice") {
				if (row.is_approved == 1) {
					return true;
				}
			}
			if (this.type == "purchase-order") {
				if (row.request_po_id) {
					let ApproveHideData = find(row.line_itemsss, (row) => row.is_approve == 0);

					if (!ApproveHideData) {
						return true;
					}
				}
				if (
					row.status_value == "approved" ||
					row.receive_status == 2 ||
					row.status_value == "rejected" ||
					row.receive_status == 5 ||
					row.force_close_status == 1
				) {
					return true;
				} else if (row.status_value == "sent_for_approval") {
					return true;
				}
			}
			if (this.type == "request-po") {
				if (
					row.status_value == "approved" ||
					row.receive_status == 2 ||
					row.status_value == "rejected" ||
					row.receive_status == 5 ||
					row.status_value == "pending" ||
					row.receive_status == 7 ||
					row.force_close_status == 1
				) {
					return true;
				} else if (row.status_value == "sent_for_approval") {
					return true;
				}
			}
			if (this.type == "quotation") {
				if (row.status != 1) {
					return true;
				}
			}
			if (this.type == "incharge-officer") {
				if (row.is_resigned == 1) {
					return true;
				}
			}
			if (this.type == "product") {
				if (row.status == 3) {
					return true;
				}
			}

			if (this.type == "project") {
				if (row.status == 3) {
					return true;
				}
			}

			return false;
		},
		is_delete_disabled(row) {
			if (this.type == "request-po") {
				if (
					row.status_value == "approved" ||
					row.receive_status == 2 ||
					row.status_value == "rejected" ||
					row.receive_status == 5 ||
					row.status_value == "pending" ||
					row.receive_status == 7 ||
					row.force_close_status == 1
				) {
					return true;
				} else if (row.status_value == "sent_for_approval") {
					return true;
				}
			}
			if (this.type == "purchase-order") {
				if (
					row.status_value == "approved" ||
					row.receive_status == 2 ||
					row.status_value == "rejected" ||
					row.receive_status == 5 ||
					row.force_close_status == 1
				) {
					return true;
				} else if (row.status_value == "sent_for_approval") {
					return true;
				}
			}
			if (this.type == "quotation") {
				if (row.status != 1) {
					return true;
				}
			}
			if (this.type == "customer" || this.type == "supplier") {
				return true;
			}
			
			if (this.type == "project") {
				if (row.status == 3) {
					return true;
				}
			}

			return false;
		},
		is_checkin(row) {
			if (this.type == "checkout") {
				if (row.inventory > 0) {
					return false;
				}
			}
			return true;
		},
		getTwoCharacter(word) {
			if (word) {
				return word.slice(0, 2).toUpperCase();
			}
			return "";
		},
		getFilteredSupplers(suppliers) {
			const _supplier = filter(suppliers, (row) => row.id);
			return _supplier;
		},
		getStatusTooltipText(data) {
			if (data.force_close_status) {
				return data.force_closed_value;
			} else if (data.receive_status) {
				return data.receive_status_value;
			}
		},
		/* .slice(0,1).toUpperCase() */
		getStatusText(data) {
			if (data.force_close_status) {
				return data.force_closed_value.slice(0, 1).toUpperCase();
			} else if (data.receive_status) {
				return data.receive_status_value.slice(0, 1).toUpperCase();
			}
		},
		getRecieveStatus(data) {
			return data.reverse();
		},
	},
	components: {
		Chip,
		Status,
		Dialog,
		ShowValue,
		ShowPrice,
		ShowTextAreaValue,
		ImageTemplate,
		TableDateTime,
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"currentPage",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
	},
};
</script>

<style>
.text-overflow{
	max-width:200px;
	white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
</style>